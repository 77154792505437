import React, {useContext} from 'react'
import { GlobalDataContext } from '../context/context'
import HeroSection from "../components/Home/HaderHome";
import BlockAboutOne from "../components/About/BlockAboutOne";
import Values from "../components/About/Values";
import Directories from "../components/Home/Directories";
import BlockHomeOne from "../components/Home/BlockHomeOne";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome.js"
import BlockHomeTwo from "../components/Home/BlockHomeTwo";
import VideoPromocional from "../components/Home/VideoPromocional";

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />
        <HeroSection />
        <div className="mt-[-110px]">
          <Values />
        </div>
        <BlockHomeOne />
        <Directories />
         {/* video promocional */}
         {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
            />
            : null
        }
        <BlockHomeTwo/>
        <CounterUp />
        <BlockAboutOne />
        <ServicesHome />
        <div className="bg-[#920101] py-[100px]">
          <FormHome />
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
